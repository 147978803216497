<template lang="">
<div>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
        <v-card-title></v-card-title>
            <v-card-text class="mt-2 " style="text-align:center">
                <h3>เริ่มการดาวน์โหลด</h3>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="bg-purple text-white darken-1"  @click="goDownload">
                    ตกลง
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <div>
        <vue-html2pdf 
        :html-to-pdf-options="htmlToPdfOptions" 
        :image="{ type: 'jpg', quality: 1 }"
        :show-layout="layout" :float-layout="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="1800" :filename="filename ? full_name : filename"  :manual-pagination="false" pdf-format="a4" pdf-content-width="100%" ref="html2Pdf">
            <!-- <v-row class="d-flex justify-end ma-2">
                <v-btn color="bg-purple text-white darken-1" rounded @click="generateReport">ดาวน์โหลดผลคะแนนอีกครั้งหากไม่ขึ้นอัตโนมัติ</v-btn>
            </v-row> -->
            <section slot="pdf-content">
            <v-row class="align-center flex-column my-auto mx-auto" style="margin:10px;"></v-row>
                <v-col  lg="12" md="12" sm="12" xs="12"  style="text-align: -webkit-center; background-color:white">
                    <div>
                        <h2 class="text-purple">ผลการทดสอบ</h2>
                        <span>แบบคัดกรองหาผู้มีภาวะสมองเสื่อมสำหรับญาติและผู้ดูแล Thai Alzheimer's Questionnaire (Thai-AZQ)</span>
                        <br>
                        <br>
                        <v-card outlined style=" border-radius: 7px;">
                            <v-card-text>
                                <v-simple-table dense class="overflow-y-auto">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    คำถาม
                                                </th>
                                                <th class="text-center">
                                                    <span>ตอบ</span>
                                                </th>
                                                <th class="text-center">
                                                    คะแนน
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in dataset" :key="item.name" style="font-size:10px">
                                                <td>{{ item.name }}</td>
                                                <td class="text-right">{{ item.ans=='true' ? 'ใช่' : 'ไม่ใช่' }}</td>
                                                <td class="text-right">{{ item.ans=='true' ? item.a_point :  0 }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                        <!-- <v-img  src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png" contain  max-height="15vh" ></v-img> -->
                        <br>
                        <v-row style="margin-top:-25px">

                            <v-col >
                                <v-card class="mx-auto" outlined>
                                    <v-list-item three-line>
                                    
                                        <v-list-item-content style="transform: translate(7%,0%);">
                                            <v-card-text class="overflow-y-auto" style="height:110px;padding: 0px;">
                                                <span v-html="total_point_detail.mean"></span>
                                                <br><br>
                                                <span v-html="total_point_detail.suggest"></span>
                                            </v-card-text>
                                        </v-list-item-content>

                                        <v-list-item-avatar tile size="80" color="white">
                                            <h3 class="text-black text-right"> <b>
                                                    <h2>{{total_point}}</h2>
                                                </b> คะแนน</h3>
                                        </v-list-item-avatar>
                                    </v-list-item>

                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- <v-btn @click="onTouch" x-large rounded class="text-white bg-purple">เริ่มทำแบบสอบถาม</v-btn> -->
                        <div>
                            <v-row>

                                <v-col cols="2" style="transform: translate(40%,-7%);">
                                    <v-img eager contain width="70px" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png"></v-img>
                                </v-col>

                                <!-- <v-img contain eager width="120px" height="70px" src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png"></v-img> -->
                                <v-col cols="7" style="transform: translate(7%,0%); padding-right:0px; padding-left:0px; font-size:13px">

                                    ทดสอบเมื่อ : {{new Date().toLocaleString('th-TH', { dateStyle: 'long'})}}
                                    <br>

                                    ผู้ทำแบบทดสอบ : {{full_name}}{{' '}}
                                    {{' '}}&nbsp;&nbsp;&nbsp;
                                    อายุ : {{age}} {{' ปี '}}
                                    {{' '}}&nbsp;&nbsp;&nbsp;
                                    เบอร์โทรศัพท์ : {{phone_number}}

                                </v-col>
                                <v-col cols="3" style="max-width: 20%; transform: translate(20%,0%);">
                                    <v-img eager contain width="1000px" src="@/assets/img/checkmong/logo/hhc_eisai.jpg"></v-img>
                                    <!-- <v-img eager contain  width="700px"  src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png"></v-img> -->
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
            </section>

        </vue-html2pdf>
    </div>

</div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
export default {
    props: ['score'],
    components: {
        VueHtml2pdf,

    },
    data() {
        return {
            layout: false,
            dialog: false,
            filename: '',
            modalAddname: false,
            phone_number: '',
            prefix: '',
            full_name: '',
            age: '',
            total_point: 0,

            dataset: [],
            total_point_detail: {},
            query: '',
            htmlToPdfOptions: {
                margin: 0,
                filename: '',
                image: {
                        type: 'jpeg', 
                        quality: 1
                    },
                
                    enableLinks: false,
                
                    html2canvas: {
                        scale: 2,
                        useCORS: false
                    },
                
                    jsPDF: {
                        unit: 'in',
                        format: 'a4',
                        orientation: 'portrait'
                    }

            }
        };
    },
    Create() {

    },
    mounted() {
        //console.log('mounted')
        this.getValueFrom()
        this.setDataset()
        //console.log(this.dataset)

        // // 
        // this.getValueFrom()
        this.setTotalPointDetail()
        this.setFileName()
        //console.log(this.filename)
        this.dialog = true


    },

    methods: {
        // onImgLoad(){
        //         this.generateReport()
        // },
        goDownload(){
            this.dialog = false
            this.layout = true
            setTimeout(() => {
                this.generateReport()
            }, 1000);
        },
        setFileName() {
            if (this.full_name.length <= 0) {
                this.filename = new Date().toLocaleString('th-TH', {
                    dateStyle: 'long'
                }) + '_ผลทดสอบ AZQ'
                this.htmlToPdfOptions.filename = this.filename
            } else {
                this.filename = this.full_name + '_' + new Date().toLocaleString('th-TH', {
                    dateStyle: 'long'
                }) + '_ผลทดสอบ AZQ'
                this.htmlToPdfOptions.filename = this.filename
            }
        },
        getValueFrom() {

            this.query = this.$route.query
            //console.log(this.query)
        },
        onProgress(event) {
            //console.log(`Processed: ${event} / 100`);
        },
        setTotalPointDetail() {
            if (this.total_point <= 3) {
                this.total_point_detail = {
                    'range': 'คะแนน 0-3',
                    'mean': 'ภาวะความจำปกติ <br> (Normal cognition)',
                    'suggest': 'ท่านมีความจำปกติ ควรหลีกเลี่ยงและควบคุมปัจจัยเสี่ยง',
                }
            }
            if (this.total_point >= 4 && this.total_point <= 12) {
                this.total_point_detail = {
                    'range': 'คะแนน 4-12',
                    'mean': 'สงสัยมีภาวะผิดปกติทางพุทธิปัญญาเล็กน้อย <br> (Mild cognitive impairment)',
                    'suggest': 'ท่านเริ่มมีความผิดปกติของสมอง<br>เพียงเล็กน้อย ควรค้นหาปัจจัยเสี่ยง และควบคุม ติดตามอาการ หากมีคะแนนที่เพิ่มมากขึ้น ควรพบแพทย์',
                }
            }
            if (this.total_point >= 13) {
                this.total_point_detail = {
                    'range': 'คะแนนตั้งแต่ 13',
                    'mean': "สงสัยมีภาวะสมองเสื่อมอัลไซเมอร์ <br>(Alzheimer's disease)",
                    'suggest': 'ควรพบแพทย์เพื่อตรวจหาสาเหตุ และทำการรักษา',
                }
            }

        },
        setDataset() {
            const azq_ans_temp = this.query

            this.total_point = azq_ans_temp.total_point
            this.full_name = azq_ans_temp.full_name
            this.age = azq_ans_temp.age
            this.phone_number = azq_ans_temp.phone_number

            //console.log('=========', azq_ans_temp)

            this.dataset = [{
                    name: '1. คนที่คุณดูแลเป็นคนขี้ลืมหรือไม่',
                    ans: azq_ans_temp.ans_1,
                    a_point: 1
                },
                {
                    name: '2. ถ้าใช่ เขามีอาการขี้ลืมมากขึ้นภายใน 2-3 ปีที่ผ่านมาหรือไม่',
                    ans: azq_ans_temp.ans_2,
                    a_point: 1
                },
                {
                    name: '3. คนที่คุณดูแล ชอบถามซ้ำหรือพูดซ้ำประโยคเดิมหรือเรื่องเดิมๆ หลายครั้งในวันเดียวหรือไม่',
                    ans: azq_ans_temp.ans_3,
                    a_point: 2
                },
                {
                    name: '4. คุณต้องคอยเตือนย้ำเรื่องต่างๆ เช่น เรื่องนัด หรือผู้ป่วยลืมนัดบ่อยๆ',
                    ans: azq_ans_temp.ans_4,
                    a_point: 1
                },
                {
                    name: '5. เขาวางของผิดที่มากกว่า 1 ครั้งต่อเดือน',
                    ans: azq_ans_temp.ans_5,
                    a_point: 1
                },
                {
                    name: '6. เขาจะสงสัยว่า มีใครซ่อนหรือขโมยของ ถ้าหาของนั้นไม่พบ',
                    ans: azq_ans_temp.ans_6,
                    a_point: 1
                },
                {
                    name: '7. เขามักจําวัน วันที่ เดือน ปี และเวลาไม่ได้ หรือ เช็คดูวันที่ มากกว่า 1 ครั้งต่อวัน',
                    ans: azq_ans_temp.ans_7,
                    a_point: 2
                },
                {
                    name: '8. เขารู้สึกหลงเมื่อไปสถานที่ที่ไม่คุ้นเคย',
                    ans: azq_ans_temp.ans_8,
                    a_point: 1
                },
                {
                    name: '9. เมื่ออยู่นอกบ้านหรือขณะเดินทางเขาจะรู้สึกสับสนมากกว่าอยู่ที่บ้าน',
                    ans: azq_ans_temp.ans_9,
                    a_point: 1
                },
                {
                    name: '10. ถ้าไม่นับขีดจํากัดด้านร่างกาย เขามีปัญหาด้านการการรับ-จ่ายเงิน (การใช้จ่ายเงินสด, การคํานวณเงินทอน)',
                    ans: azq_ans_temp.ans_10,
                    a_point: 1
                },
                {
                    name: '11. เขามีปัญหาการจ่ายบิลค่าใช้จ่าย หรือการจัดการเรื่องการเงิน (บิลค่าไฟฟ้า, ค่าโทรศัพท์, ค่าบัตรเครดิต)',
                    ans: azq_ans_temp.ans_11,
                    a_point: 2
                },
                {
                    name: '12. เขาลืมกินยาหรือกินยาไม่สม่ำเสมอ',
                    ans: azq_ans_temp.ans_12,
                    a_point: 1
                },
                {
                    name: '13. เขาขับรถหลงทาง หรือทําให้คุณกังวลเกี่ยวกับการขับรถของเขา',
                    ans: azq_ans_temp.ans_13,
                    a_point: 1
                },
                {
                    name: '14. เขาใช้เครื่องมืออุปกรณ์ที่เคยทําทุกวันได้ยากขึ้น เช่น เตาหุงต้ม, โทรศัพท์, รีโมทคอนโทรล',
                    ans: azq_ans_temp.ans_14,
                    a_point: 1
                },
                {
                    name: '15. ถ้าไม่นับขีดจํากัดด้านร่างกายเขาทําความสะอาดบ้าน หรือซ่อมแซมของใช้ในบ้านได้ลําบากกว่าเดิม',
                    ans: azq_ans_temp.ans_15,
                    a_point: 1
                },
                {
                    name: '16. ถ้าไม่นับขีดจํากัดด้านร่างกาย เขาเลิกเล่นกีฬา หรืองานอดิเรกที่ทําเป็นประจํา',
                    ans: azq_ans_temp.ans_16,
                    a_point: 1
                },
                {
                    name: '17. เขาเริ่มหลงทางในพื้นที่ๆคุ้นเคย เช่นในละแวกบ้าน',
                    ans: azq_ans_temp.ans_17,
                    a_point: 2
                },
                {
                    name: '18. เขาไม่เข้าใจทิศหรือจําทางไม่ได้',
                    ans: azq_ans_temp.ans_18,
                    a_point: 1
                },
                {
                    name: '19. เขามีปัญหาในการนึกคําเรียกสิ่งต่างๆ นอกจากชื่อคน',
                    ans: azq_ans_temp.ans_19,
                    a_point: 1
                },
                {
                    name: '20. เขาสับสนในการเรียกชื่อสมาชิกในครอบครัว หรือในกลุ่มเพื่อนสนิท',
                    ans: azq_ans_temp.ans_20,
                    a_point: 2
                },
                {
                    name: '21. เขามีปัญหาในการจำชื่อคนที่คุ้นเคย',
                    ans: azq_ans_temp.ans_21,
                    a_point: 2
                },
            ]
        },
        generateReport() {
            this.modalAddname = false
            //console.log('-------------')
            //console.log(this.full_name)
            //console.log(this.full_name.length)
            if (this.full_name.length <= 0) {
                this.filename = new Date().toLocaleString('th-TH', {
                    dateStyle: 'long'
                }) + '_ผลทดสอบ AZQ'
                this.htmlToPdfOptions.filename = this.filename
                this.$refs.html2Pdf.generatePdf()
            } else {
                this.filename = this.full_name + '_' + new Date().toLocaleString('th-TH', {
                    dateStyle: 'long'
                }) + '_ผลทดสอบ AZQ'
                this.htmlToPdfOptions.filename = this.filename
                this.$refs.html2Pdf.generatePdf()
            }

            // //console.log(this.$refs.html2Pdf.downloadPdf())
        },

    },

};
</script>

<style scoped>
.imgbox_2 {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 850px;
}

.custom-field {
    color: black;
}

.v-menu__content .v-select__selection,
.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: black !important;
}
</style>
